import React from "react"
import Layout from "../../components/Layout"
import loadable from "@loadable/component"

// Lazy load the MathStarter component
const MathStarter = loadable(() => import("../../components/mathstarter"), {
  fallback: <div className="loading-fallback">Loading Math Starter...</div>, // Fallback while loading
})

export default function TrackHero() {
  return (
    <Layout>
      <MathStarter />
    </Layout>
  )
}
